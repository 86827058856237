import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { FormattedMessage } from "gatsby-plugin-intl"

class PasswordResetLinkSent extends React.Component {

  render () {
    return (
    <Layout>

      <SEO title="Reactivation link sent" />

      <div className="white-container mb-4">


        <div className="text-center mb-4">

          <span className="icon-confirm-email fs-50"></span>

        </div>

        <h1 className="text-center"><FormattedMessage id="1_1_3_login_forgot_password_link_sent.title" /></h1>
        <p className="text-center"><FormattedMessage id="1_1_3_login_forgot_password_link_sent.description" values={{ email: this.props.location.state ? this.props.location.state.email : "" }} /></p>

      </div>

      <p className="text-center grey-border"><strong className="dark-grey"><FormattedMessage id="generic.forms.form_mentions.not_received_activation" /></strong><br />
        <FormattedMessage id="generic.forms.form_mentions.check_your_spam" /><br />
        <FormattedMessage id="generic.forms.form_mentions.or" /><br />
        <a href="#" className="link-nav"><strong><FormattedMessage id="generic.forms.form_mentions.send_a_new_link" /></strong></a>
      </p>

    </Layout>
    )
  }
}


export default PasswordResetLinkSent
